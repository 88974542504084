import React, {FC, useEffect} from 'react';
import {toPersianNumberFromNumber} from '../../../core/utils/number.utils';
import {COIN_IMAGE} from '../campaign/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {MY_DISCOUNT, SCORE} from '../../../core/navigation/CONSTANTS';

import {LOGIN_STATE_KEY, SCORE_STATE_KEY} from '../../../core/services/CONSTANTS';
import {LOGIN_ACTION} from '../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../core/redux/store';
import {LOGO_IMAGE_URL} from './CONSTANTS';
import {ScoreService} from '../../score/service/score.service';
import {ColumnComponent} from '../../../core/components/base/column.component';
import {RowComponent} from '../../../core/components/base/row.component';
import {useRouter} from 'next/router';
import useLocalStorageState from '../../../core/utils/useLocalStorage';
import Image from 'next/image';

const MyClubComponent: FC = () => {
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [userScore, setUserScore] = useLocalStorageState<number>(SCORE_STATE_KEY, 0);
  const { t } = useTranslation();
  const router = useRouter();
  let dispatch = useDispatch<AppDispatch>();

  const scoreService = new ScoreService();

  useEffect(() => {
    if (isLogin) {
      scoreService.getScore(1, 5).subscribe((data) => {
        if (data.data && data.data.results && data.data.results.length > 0) {
          setUserScore(data.data.total_point);
        }
      });
    }
  }, [isLogin]);

  function navigateToScorePage() {
    router.push({ pathname: SCORE, search: `shTitle=${t('clubText.scoresHistory')}` });
  }

  function navigateToMyDiscountPage() {
    router.push({ pathname: MY_DISCOUNT, search: `shTitle=${t('clubText.myDiscounts')}` });
  }

  function getBaseComponents() {
    return (
      <ColumnComponent className="p-3">
        <RowComponent
          className="card card-1 p-2 scoreHistoryFrame totalCenter pointer"
          onClick={() => navigateToScorePage()}
        >
          <div className="grow text-start p-2">{t('clubText.scoresHistory')}</div>
          <div className="flex flex-row w-auto">
            <div className="text-lg mx-3">{toPersianNumberFromNumber(userScore ?? 0)}</div>
            <Image fill={true} sizes={'100%'} alt={''} src={COIN_IMAGE} className="coinImage !relative" />
          </div>
        </RowComponent>
        <RowComponent
          className="card card-1 p-2 mt-3 myDiscountsFrame pointer"
          onClick={() => navigateToMyDiscountPage()}
        >
          <div className="grow text-start p-2">{t('clubText.myDiscounts')}</div>
        </RowComponent>
      </ColumnComponent>
    );
  }

  function loginUser() {
    dispatch({
      type: LOGIN_ACTION,
      openLoginPage: true,
      state: LOGIN_STATE
    });
  }

  function getLoginRequiredPage() {
    return (
      <ColumnComponent className="totalCenter relative" style={{ height: 'calc(100vh - 12rem)' }}>
        <Image fill={true} sizes={'100%'} alt={''} className="clubAppLogoImage !relative" src={LOGO_IMAGE_URL} />
        <div className="mt-4 fs-7 mTextLightBlue px-4 text-center">{t('clubText.loginRequired')}</div>
        <div
          onClick={() => loginUser()}
          className="mt-3 calculateButton text-bold text-base text-center container-md absolute bottom-5 "
        >
          {t('profile.loginToAccount')}
        </div>
      </ColumnComponent>
    );
  }

  function getMyClubPage() {
    if (isLogin) {
      return getBaseComponents();
    } else {
      return getLoginRequiredPage();
    }
  }

  return <ColumnComponent className={'w-full md:w-3/4 lg:w-1/2 '}>{getMyClubPage()}</ColumnComponent>;
};

export default React.memo(MyClubComponent);
