import React, {FC, useEffect, useState} from 'react';
import {ColumnComponent} from '../../../core/components/base/column.component';
import {MissionService} from './service/mission.service';
import {MissionResult} from './model/mission.model';
import InfiniteScroll from 'react-infinite-scroll-component';
import Spinner from 'react-spinner-material';
import {Loader} from '../../../core/components/loader.component';
import {onShortcutClickFromUrl} from '../../Home/components/MenuBuilder/shortcut/service/shortcut.service';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../core/redux/store';
import {useRouter} from 'next/router';

import {LOGIN_STATE_KEY} from '../../../core/services/CONSTANTS';
import {MissionItemComponent} from './component/missionItem.component';
import useLocalStorageState from '../../../core/utils/useLocalStorage';

export const Mission: FC = () => {
  const missionService = new MissionService();
  let [missionList, setMissionList] = useState<MissionResult[]>();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [page, setPage] = useState(1);
  let dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const [hasToolbar, setHasToolbar] = useState(false);
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);

  useEffect(() => {
    missionService.getMissions(isLogin, page, 20).subscribe(
      (data) => {
        if (data.data && data.data.results && data.data.results.length > 0) {
          if (page > 1) {
            setMissionList(missionList?.concat(data.data.results));
          } else {
            setMissionList(data.data.results);
            if (data.data.results.length < 9) {
              setHasNextPage(false);
            }
          }
          if (!data.data.next) {
            setHasNextPage(false);
          }
        } else {
          setHasNextPage(false);
        }
        const { shTitle } = router.query;
        if (shTitle) {
          setHasToolbar(true);
        }
      },
      () => {
        setHasNextPage(false);
      }
    );
  }, [page]);

  function itemClickLink(title: string, linkUrl: string) {
    onShortcutClickFromUrl(linkUrl, title, router, dispatch);
  }

  return (
    <ColumnComponent>
      <div className={"flex flex-col w-full md:w-3/4 lg:w-1/2"}>
        {missionList ? (
          <InfiniteScroll
            dataLength={missionList.length}
            next={() => setPage(page + 1)}
            hasMore={hasNextPage}
            height={hasToolbar ? 'calc(100vh - 2.5rem)' : '100vh'}
            loader={
              <div className="flex justify-center" style={{ width: '100%', height: '3rem' }}>
                <Spinner radius={40} size={40} stroke={3} color={'#9c4000'} visible={true} />
              </div>
            }
          >
            <div className="w-full px-4">
              {missionList.map((item, index) => (
                <MissionItemComponent mission={item} />
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <Loader isFullScreen={true} />
        )}
      </div>
    </ColumnComponent>
  );
};
