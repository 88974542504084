import React, {FC} from 'react';
import {ColumnComponent} from '../../../../core/components/base/column.component';
import {convertDateToPersian} from '../../../../core/utils/DateUtils';
import {toPersianNumber} from '../../../../core/utils/number.utils';
import {RowComponent} from '../../../../core/components/base/row.component';
import {Loader} from '../../../../core/components/loader.component';
import {CampaignResults} from '../model/campaign.model';
import {CAMPAIGN} from '../../../../core/navigation/CONSTANTS';
import {useTranslation} from 'react-i18next';
import {useRouter} from 'next/router';
import Image from 'next/image';

interface CampaignComponentProps {
  campaignList: CampaignResults[] | undefined;
  campaignStateChange: any;
}

export const DeActiveCampaignComponent: FC<CampaignComponentProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter();

  function navigateToCampaignDetail(campaignResult: CampaignResults) {
    router.push({ pathname: CAMPAIGN + `/${campaignResult.id}`, search: `shTitle=${campaignResult.title}` });
  }
  return (
    <div className="w-full">
      {props.campaignList ? (
        <div className="w-full flex flex-col  px-3 pb-3">
          {props.campaignList.map((campaign, index) => (
            <ColumnComponent key={index}>
              <div className="text-start w-full text-base p-1 mt-2">
                {convertDateToPersian(new Date(campaign.finish_date * 1000))}
              </div>
              <ColumnComponent
                className="card card-box pb-2 text-start mt-2"
                onClick={() => navigateToCampaignDetail(campaign)}
              >
                <div className="flex relative w-full">
                  <Image
                    fill={true}
                    sizes={'100%'}
                    src={campaign.image}
                    alt=""
                    className="card card-1 activeCampaignImage !relative !rounded-t-lg !rounded-b-none"
                  />
                </div>
                <div className="w-full p-2 text-start flex flex-row">
                  <div className="grow text-base">{toPersianNumber(campaign.title)}</div>
                </div>
              </ColumnComponent>
            </ColumnComponent>
          ))}
          <RowComponent className="card card-box p-3 text-start mt-2" onClick={props.campaignStateChange}>
            <div className="grow text-base text-bold">{t('clubText.activeCampaign')}</div>
          </RowComponent>
        </div>
      ) : (typeof props.campaignList) == undefined ?
          <Loader isFullScreen={true}/> : null}
    </div>
  );
};
