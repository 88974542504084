import React, {FC} from 'react';
import {RowComponent} from '../../../../core/components/base/row.component';
import {Loader} from '../../../../core/components/loader.component';
import {CampaignResults} from '../model/campaign.model';
import {useTranslation} from 'react-i18next';
import {ActiveCampaignItemComponent} from './activeCampaignItem.component';

interface CampaignComponentProps {
    campaignList: CampaignResults[] | undefined;
    campaignStateChange: any;
}

export const ActiveCampaignComponent: FC<CampaignComponentProps> = (props) => {
    const {t} = useTranslation();

    return (
        <div className="w-full">
            {props.campaignList ? (
                <div className="w-full flex flex-col px-3 pb-3">
                    <div className="flex w-full flex-col">
                        {props.campaignList.length > 0 ? (
                            props.campaignList.map((campaign, index) => (
                                <ActiveCampaignItemComponent campaign={campaign} key={index}/>
                            ))
                        ) : (
                            <Loader isFullScreen={false}/>
                        )}
                        <RowComponent className="card card-box p-3 text-start mt-2 totalCenter"
                                      onClick={props.campaignStateChange}>
                            <div className="grow text-base">{t('clubText.finishedCampaign')}</div>
                        </RowComponent>
                    </div>
                </div>
            ) : (typeof props.campaignList) == undefined ?
                <Loader isFullScreen={true}/> : null
            }
        </div>
    );
};
