import moment from 'moment-jalaali';
import momentEnglish from 'moment';
import momentHijri from 'moment-hijri';
import {toPersianNumber} from './number.utils';
import {PERSIAN} from '../services/CONSTANTS';
import {getTwoDigitNumber} from '../../page/tools/predict/predict.utils';

export default function formatDate(date: Date): string {
  return date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate();
}

export const convertDateToPersian = (date: Date): string => {
  moment.locale(PERSIAN);
  moment.loadPersian({ dialect: 'persian-modern' });
  const momentJalali = moment(date);
  return toPersianNumber(momentJalali.jDate() + ' ' + momentJalali.format('jMMMM') + ' ' + momentJalali.jYear());
};

export const convertDateToPersianSimple = (date: Date): string => {
  moment.locale(PERSIAN);
  moment.loadPersian({ dialect: 'persian-modern' });
  const momentJalali = moment(date);
  return toPersianNumber(momentJalali.jYear() + '/' + (momentJalali.jMonth() + 1) + '/' + momentJalali.jDate());
};

export const convertDateToPersianSimpleReal = (date: Date): string => {
  moment.locale(PERSIAN);
  moment.loadPersian({ dialect: 'persian-modern' });
  const momentJalali = moment(date);
  return toPersianNumber(momentJalali.jYear() + '/' + momentJalali.jMonth() + '/' + momentJalali.jDate());
};

export const convertDateToIslamic = (date: Date): string => {
  momentHijri.locale('ar-SA');
  const momentH = momentHijri(date);
  return toPersianNumber(momentH.iYear() + '/' + (momentH.iMonth() + 1) + '/' + momentH.iDate());
};

export const convertDateToIslamicReal = (date: Date): string => {
  momentHijri.locale('ar-SA');
  const momentH = momentHijri(date);
  return toPersianNumber(momentH.iYear() + '/' + momentH.iMonth() + '/' + momentH.iDate());
};

export const convertDateToCivil = (date: Date): string => {
  momentEnglish.locale('fa');
  const momentC = momentEnglish(date);
  return toPersianNumber(momentC.year() + '/' + (momentC.month() + 1) + '/' + momentC.date());
};

export const convertDateToCivilReal = (date: Date): string => {
  momentEnglish.locale('fa');
  const momentC = momentEnglish(date);
  return toPersianNumber(momentC.year() + '/' + momentC.month() + '/' + momentC.date());
};

export const convertDateToPersianWithTime = (date: Date): string => {
  moment.locale(PERSIAN);
  moment.loadPersian({ dialect: 'persian-modern' });
  const momentJalali = moment(date);
  return toPersianNumber(
    momentJalali.jDate() +
      ' ' +
      momentJalali.format('jMMMM') +
      ' ' +
      momentJalali.jYear() +
      ' | ' +
      momentJalali.hour() +
      ':' +
      getTwoDigitNumber(momentJalali.minute())
  );
};

export const convertSecondsToHourMinuteSecond = (secs: number): string => {
  let hours = Math.floor(secs / 3600);
  let minutes = Math.floor(secs / 60) % 60;
  let seconds = secs % 60;
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? '0' + v : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};
