export const FINANCIAL_HISTORY_URL = 'r/payment/transaction/v3/user/';

export const PAGE_URL_TAG = 'page';
export const PAGE_SIZE_URL_TAG = 'page_size';

export const CHARGE_TYPE = 'charge';
export const BILL_TYPE = 'bill';
export const INTERNET_PACKAGE_TYPE = 'internet_package';
export const DEPOSIT = 'deposit';
export const WITHDRAW = 'withdraw';

export const SUCCESS_IMAGE = '/image/svg_ic_success.svg';
export const UN_SUCCESS_IMAGE = '/image/svg_ic_error.svg';

export const WATER = '1';
export const ELECTRICITY = '2';
export const GAS = '3';
export const PHONE = '4';
export const MOBILE = '5';
export const URBAN_TOLLS = '7';
export const CAR_FINES = '9';

export const STATE_SUCCESS = 'success';
export const STATE_FAILED = 'failed';
export const STATE_RETURNED = 'returned';
export const STATE_UN_SUCCESS = 'unsuccessfull';
export const STATE_STARTED = 'started';

export const TYPE_CREDIT = 'CREDIT';
export const TYPE_DEBT = 'DEBT';
