import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DiscountService} from './service/discountService';
import {DiscountResults, UseDiscountData, UseDiscountModel} from './model/discount.model';
import {Loader} from '../../../core/components/loader.component';
import LayoutDrawer from '../../../core/components/layoutDrawer.component';
import {toPersianNumber, toPersianNumberFromNumber} from '../../../core/utils/number.utils';
import {COIN_IMAGE} from '../campaign/CONSTANTS';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import {LOGIN_STATE_KEY, SCORE_STATE_KEY} from '../../../core/services/CONSTANTS';
import {LOGIN_ACTION} from '../../profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../profile/components/Login/CONSTANTS';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../../core/redux/store';
import {SUCCESS_IMAGE, UN_SUCCESS_IMAGE} from '../../tools/transactions/CONSTANTS';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import {MESSAGE_ACTION} from '../../../core/redux/reducers/messageActions';
import {ColumnComponent} from '../../../core/components/base/column.component';
import {RowComponent} from '../../../core/components/base/row.component';
import {Avatar, SwipeableDrawer} from '@mui/material';
import {DiscountItemComponent} from './component/discountItem.component';
import {getDiscountItemShow, saveDiscountItemShow} from '../../../core/sessionStorage.service';
import useLocalStorageState from '../../../core/utils/useLocalStorage';
import Image from 'next/image';

interface DiscountPageProps {
  chooseDiscountId?: string;
}

const DiscountPage: FC<DiscountPageProps> = (props) => {
  const { t } = useTranslation();
  let dispatch = useDispatch<AppDispatch>();
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [userScore, setUserScore] = useLocalStorageState<number>(SCORE_STATE_KEY, 0);

  const [discountResultState, setDiscountResultState] = useState(false);
  const [discountSuccess, setDiscountSuccess] = useState(false);

  const discountService = new DiscountService();

  const [discountList, setDiscountList] = useState<DiscountResults[]>([]);
  const [detailDiscount, setDetailDiscount] = useState<DiscountResults>();
  const [resultOpen, setResultOpen] = useState(false);

  const [useDiscount, setUseDiscount] = useState<UseDiscountData>();
  const chooseDiscountOpen = React.useRef(false);

  useEffect(() => {
    discountService.getDiscounts().subscribe((data) => {
      if (data.data && data.data.results && data.data.results.length > 0) {
        setDiscountList(data.data.results);
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (discountList.length > 0) {
      if (props.chooseDiscountId && !chooseDiscountOpen.current) {
        chooseDiscountOpen.current = true;
        let chooseDiscountItem: DiscountResults | null = null;
        discountList.forEach((item) => {
          if (item.id == Number(props.chooseDiscountId)) {
            chooseDiscountItem = item;
          }
        });
        if (chooseDiscountItem && !getDiscountItemShow()) {
          saveDiscountItemShow('1');
          clickDetails(chooseDiscountItem);
        }
      }
    }
  }, [discountList]);

  // useEffect(() => {
  //     if (props.chooseDiscountId && !resultOpen) {
  //         discountList.map((item) => {
  //             if (item.id == Number(props.chooseDiscountId)) {
  //                 clickDetails(item)
  //             }
  //         })
  //     }
  // }, [props.chooseDiscountId]);

  function clickDetails(item: DiscountResults) {
    if (!isLogin) {
      dispatch({
        type: LOGIN_ACTION,
        openLoginPage: true,
        state: LOGIN_STATE
      });
    } else {
      setDetailDiscount(item);
      setResultOpen(true);
    }
  }

  function getDiscountClick(id: number | undefined) {
    if (id) {
      discountService.useDiscounts(id).subscribe((data) => {
        let discount = (data as UseDiscountModel).data;
        if (discount && discount.coupon) {
          setUseDiscount(discount);
          setDiscountSuccess(true);
          setDiscountResultState(true);
        } else {
          setDiscountSuccess(false);
          setDiscountResultState(true);
        }
      });
    }
  }

  function setReturnDiscountResult() {
    setResultOpen(false);
    setDiscountResultState(false);
  }

  function copyText(coupon: string) {
    navigator.clipboard.writeText(coupon);
    dispatch({
      type: MESSAGE_ACTION,
      message: t('clubText.copyCodeText'),
      sort: 'success'
    });
  }

  function setCloseSwiper() {
    setResultOpen(!resultOpen);
    saveDiscountItemShow('0');
  }

  return (
    <ColumnComponent>
      <div className={"flex flex-col w-full md:w-3/4 lg:w-1/2"}>
        {discountList && discountList.length > 0 ? (
          discountList.map((item) => (
            <ColumnComponent className="totalCenter pt-2 pb-2 lg:w-2/3">
              <DiscountItemComponent discount={item} itemClick={(discount) => clickDetails(discount)} />
              <div className="separatorLine w-90" />
            </ColumnComponent>
          ))
        ) : (
          <Loader isFullScreen={true} />
        )}
      </div>
      <SwipeableDrawer
        anchor={'bottom'}
        open={resultOpen}
        disableSwipeToOpen={true}
        PaperProps={{ square: false, elevation: 0, style: { backgroundColor: 'transparent' } }}
        onOpen={() => setResultOpen(true)}
        onClose={() => setCloseSwiper()}
      >
        <LayoutDrawer>
          {discountResultState ? (
            <ColumnComponent className="totalCenter">
              <Image
                fill={true}
                sizes={'100%'}
                alt=""
                className="successIcon mt-2 !relative"
                src={discountSuccess ? SUCCESS_IMAGE : UN_SUCCESS_IMAGE}
              />
              <div className={`text-lg text-bold mt-2 ${discountSuccess ? 'mTextGreen' : 'mTextRed'}`}>
                {discountSuccess ? t('actionSuccess') : t('actionFail')}
              </div>
              <RowComponent className=" w-full p-3 items-center">
                <div className="w-1/3 totalCenter flex flex-col">
                  <Avatar src={useDiscount?.image} className="discountImage">
                    <ImageOutlinedIcon />
                  </Avatar>
                </div>
                <div className="w-2/3 totalCenter m-1 flex flex-col">
                  <div className="text-base text-bold mt-1 w-full text-start">{useDiscount?.title}</div>
                  <div className="text-sm mt-1 w-full text-start">{useDiscount?.provider ?? ''}</div>
                </div>
              </RowComponent>
              <RowComponent className={'mt-3 p-4'}>
                <div className="text-base text-bold mt-2 grow text-start">{t('clubText.discountCode')}</div>
                <div className="w-auto text-lg" onClick={() => copyText(useDiscount?.coupon ?? '')}>
                  {toPersianNumber(useDiscount?.coupon.toString() ?? '')}
                </div>
                <FileCopyOutlinedIcon
                  className="px-1"
                  fontSize={'medium'}
                  onClick={() => copyText(useDiscount?.coupon ?? '')}
                />
              </RowComponent>
              <div
                onClick={() => setReturnDiscountResult()}
                className={`mt-4 mb-4 calculateButton text-bold text-base text-center container-md`}
              >
                {t('return')}
              </div>
            </ColumnComponent>
          ) : (
            <ColumnComponent>
              <div className="text-lg text-bold p-3 text-center">{t('discountDetails')}</div>
              {detailDiscount ? (
                <ColumnComponent className="totalCenter px-4">
                  <RowComponent className="pt-2 pb-2 items-center">
                    <div className="w-1/3 flex flex-col totalCenter">
                      <Avatar src={detailDiscount.image} className="discountImage">
                        <ImageOutlinedIcon />
                      </Avatar>
                    </div>
                    <div className="w-2/3 items-center m-1 flex flex-col">
                      <div className="text-base mt-1 w-full text-start">{detailDiscount.title}</div>
                      <div className="text-base mt-1 w-full text-start">{detailDiscount.provider}</div>
                    </div>
                  </RowComponent>
                  <RowComponent className="px-4 totalCenter">
                    <div className="grow text-start text-sm justify-center">{t('clubText.requiredScore')}</div>
                    <div className="flex flex-row w-auto totalCenter">
                      <div className="text-lg mx-3">{toPersianNumberFromNumber(detailDiscount.cost)}</div>
                      <Image fill={true} sizes={'100%'} alt={''} src={COIN_IMAGE} className="coinImage !relative" />
                    </div>
                  </RowComponent>
                  <div
                    className="w-full px-4 mt-2 text-sm"
                    dangerouslySetInnerHTML={{ __html: detailDiscount.description }}
                  />
                  <div
                    className={`w-full text-center mt-2 ${
                      userScore >= detailDiscount.cost ? 'mTextGreen' : 'mTextRed'
                    }`}
                  >
                    {t(
                      userScore >= detailDiscount.cost ? 'clubText.yourScoreIsEnough' : 'clubText.yourScoreIsNotEnough'
                    )}
                  </div>
                  <div
                    onClick={() => getDiscountClick(detailDiscount?.id)}
                    className={`mt-4 mb-4 calculateButton text-bold text-base text-center`}
                  >
                    {t('clubText.getDiscountCode')}
                  </div>
                </ColumnComponent>
              ) : null}
            </ColumnComponent>
          )}
        </LayoutDrawer>
      </SwipeableDrawer>
    </ColumnComponent>
  );
};

export default React.memo(DiscountPage);
