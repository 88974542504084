import React, {FC, useEffect, useState} from 'react';
import {CampaignService} from './service/campaignService';
import {CampaignResults} from './model/campaign.model';
import {CAMPAIGN_DRAWING, CAMPAIGN_FINISHED, CAMPAIGN_IN_PROGRESS, CAMPAIGN_NOT_STARTED} from './CONSTANTS';

import {LOGIN_STATE_KEY, SCORE_STATE_KEY} from '../../../core/services/CONSTANTS';
import {ScoreService} from '../../score/service/score.service';
import {RowComponent} from '../../../core/components/base/row.component';
import {ActiveCampaignComponent} from './component/activeCampaign.component';
import {DeActiveCampaignComponent} from './component/deactiveCampaign.component';
import {ColumnComponent} from '../../../core/components/base/column.component';
import useLocalStorageState from '../../../core/utils/useLocalStorage';

const CampaignComponent: FC = () => {
  const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
  const [userScore, setUserScore] = useLocalStorageState<number>(SCORE_STATE_KEY, 0);

  const campaignService = new CampaignService();
  const [activeCampaignList, setActiveCampaignList] = useState<CampaignResults[]>();
  const [deActiveCampaignList, setDeActiveCampaignList] = useState<CampaignResults[]>();
  const [campaignState, setCampaignState] = useState(0);
  const scoreService = new ScoreService();

  useEffect(() => {
    campaignService.getCampaigns(isLogin).subscribe((data) => {
      if (data.data && data.data.results && data.data.results.length > 0) {
        setActiveCampaignList(
          data.data.results.filter(
            (result) => result.status == CAMPAIGN_IN_PROGRESS || result.status == CAMPAIGN_NOT_STARTED
          )??[]
        );
        setDeActiveCampaignList(
          data.data.results.filter((result) => result.status == CAMPAIGN_DRAWING || result.status == CAMPAIGN_FINISHED)??[]
        );
      }
    });
    if (isLogin) {
      scoreService.getScore(1, 5).subscribe((data) => {
        if (data.data && data.data.results && data.data.results.length > 0) {
          setUserScore(data.data.total_point);
        }
      });
    }
  }, []);

  return (
    <ColumnComponent>
      <RowComponent>
        <div className="w-full md:w-3/4 lg:w-1/2">
          {campaignState == 0 ? (
            <ActiveCampaignComponent
              campaignStateChange={() => setCampaignState(1)}
              campaignList={activeCampaignList}
            />
          ) : (
            <DeActiveCampaignComponent
              campaignStateChange={() => setCampaignState(0)}
              campaignList={deActiveCampaignList}
            />
          )}
        </div>
      </RowComponent>
    </ColumnComponent>
  );
};

export default React.memo(CampaignComponent);
